import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Memory Sanitizer",
  "time": "stuff",
  "index": 6,
  "type": "project",
  "hidden": false,
  "splash": "Software shim monitoring process memory consumption & checking for leaks"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://github.com/DavidPeet8/Memory-Sanitizer"
      }}>{`Memory Sanitizer`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2020`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C/C++`}</inlineCode>{` `}<inlineCode parentName="p">{`Linux`}</inlineCode>{` `}<inlineCode parentName="p">{`Make`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/Memory-Sanitizer" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`Memory Leaks are a common error in low level programs which can lead to `}<em parentName="p">{`thrashing`}</em>{` and sometimes security vulnerabilities. Common tooling for detecting and handling memory leaks includes `}<inlineCode parentName="p">{`Valgrind`}</inlineCode>{` and `}<inlineCode parentName="p">{`ASAN`}</inlineCode>{`. Although both tools are quite useful at detecting leaks, ASAN has a mandatory compile time component rendering it useless on production executables, and Valgrind is just plain slow because it goes to the additional work of emulating x86 instructions by virtualizing the CPU.`}</p>
    <p>{`Memory Sanitizer is designed to fill the void left by other tools by providing the ability to  smoke test a `}<strong parentName="p"><em parentName="strong">{`production executable`}</em></strong>{` for memory leaks. In this situation, no debug symbols exist, and optimizations are enabled, so it is highly unlikely that any memory sanitization program can provide much useful information.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Thus, `}<em parentName="p">{`Memory Santizer`}</em>{` only needs to answer one question: `}<strong parentName="p"><em parentName="strong">{`given a program, does it leak memory`}</em></strong>{`?`}</p>
    </blockquote>
    <p>{`With this goal in mind, `}<em parentName="p">{`Memory Sanitizer`}</em>{` shims memory allocation & deallocation calls  tracking memory consumption over the program lifetime. `}<em parentName="p">{`Memory Sanitizer`}</em>{` attaches to production executables by taking advantage of `}<inlineCode parentName="p">{`LD_PRELOAD`}</inlineCode>{` and dynamic linking order, essentially forcing executables to link with my memory allocation & deallocation implementations rather than those provided by the standard library. As a result of the minimal overhead in Memory sanitizer, it provides:`}</p>
    <ul>
      <li parentName="ul">{`Over 100x startup time improvement over Valgrind`}</li>
      <li parentName="ul">{`Over 100x less runtime overhead than Valgrind`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      